<template>
  <v-container fluid>
    <v-card-actions>
      <v-row class="mt-1">
        <v-col>
          <btn-voltar to="/areas" />
        </v-col>
      </v-row>
    </v-card-actions>
    <box-campos
      :carregando-salvar="carregando"
      :validacao-formulario="validacaoFormulario"
      @salvar="salvar"
    />
  </v-container>
</template>

<script>
import boxes from "@/api/boxes";

export default {
  components: {
    BoxCampos: () => import("./BoxCampos"),
  },

  data() {
    return {
      carregando: false,
      validacaoFormulario: {},
    };
  },

  methods: {
    async salvar(box) {
      this.carregando = true;
      this.validacaoFormulario = {};

      try {
        const area_id = this.$route.params.id;

        await boxes.inserir(area_id, box);
        this.$router.push(`/areas/${this.$route.params.id}/box`, () =>
          this.$snackbar.mostrar({
            cor: "success",
            mensagem: this.$mensagens._("sucesso_adicionar"),
          })
        );
      } catch (e) {
        if (e.response.status === 422) {
          this.validacaoFormulario = this.$erroApi.validacao(e);
          return;
        }

        this.$snackbar.mostrar({ cor: "error", mensagem: this.$erroApi._(e) });
      } finally {
        this.carregando = false;
      }
    },
  },
};
</script>
